.future {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.future .section-description {
    max-width: 420px;
}
.future .bubbles {
    width: 540px;
    padding-top: 56px;
}

.future .bubble-argument {
    --bubble-size: 96px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    gap: 24px;
}
.future .bubble-argument .bubble {
    background-color: #fff;
    width: var(--bubble-size);
    min-width: var(--bubble-size);
    aspect-ratio: 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.future .bubble-argument .bubble img {
    
}
.future .bubble-argument .desc {
    flex-grow: 1;
}

.future .bubble-argument .desc .title {
    margin-bottom: 16px;
}
.future .bubble-argument .desc p {
    font-size: 16px;
}

/** EXTRALARGE */
@media screen and (min-width: 1600px) {
    .bubbles {
        --shift: -80px;
        .bubble-argument:nth-child(2) {
            transform: translateX(var(--shift));
        }
        .bubble-argument:nth-child(3) {
            transform: translateX(calc(var(--shift) * 2));
        }
    }
}

/** TABLET */
@media screen and (min-width: 600px) and (max-width: 899px) {
    .future {
        justify-content: center;
    }
    .future .section-description {
        max-width: inherit;
        text-align: center;
    }
    .future .bubbles {
        width: 100%;
        display: flex;
        gap: 32px;
        /* padding-top: 40px; */
        & .bubble-argument {
            display: flex;
            flex-direction: column;
            text-align: center
        }
    }
}
/** MOBILE */
@media screen and (max-width: 599px) {
    .future .section-description {
        max-width: inherit;
    }
    .future .bubbles {
        width: inherit;
    }
}