.why-dreamaudit {
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: inherit;

    /* important pour que le navigateur passe au dessous avec le parallax */
    z-index: 2;
    position: relative;
    box-shadow: 0 -32px 32px -24px var(--shadow-color);
}
.why-dreamaudit .row {
    display: flex;
    align-items: stretch;
    gap: 32px;
}

.why-dreamaudit h2 {
    margin-bottom: 64px;
}

@media screen and (max-width: 599px) {
    .why-dreamaudit .row {
        gap: 48px;
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .why-dreamaudit .row {
        flex-direction: row;
        gap: 24px;
    }
}