.work-programs {

    --bounce-curve: cubic-bezier(.1,.8,.4,1.25);
    --animation-duration: 4s;
    --animation-delay: .4s;

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 24px;
    background-color: #fff;
}

.work-programs .control {
    animation: clickControl var(--animation-duration) var(--animation-delay) infinite var(--bounce-curve);
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 500;
}
.work-programs .control .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    border-radius: 16px;
    aspect-ratio: 1;
    background-color: var(--risk-color-light);
}
.work-programs .control .desc {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
}
.work-programs .control .desc em {
    font-size: 12px;
    line-height: 12px;
    font-style: normal;
    color: var(--risk-color-main);
}
.work-programs .control .desc p {
    font-size: 16px;
}
.work-programs .control .desc .badge {
    padding: 4px 8px;
    border-radius: 16px;
    align-self: flex-start;
    font-size: 12px;
    line-height: 12px;
    background-color: var(--accent-20);
    color: var(--accent-100);
    display: none;
}

.work-programs .control .check {
    /* background-color: var(--accent-20); */
    animation: toggleCheck var(--animation-duration) var(--animation-delay) var(--bounce-curve) infinite;
    animation-fill-mode: none;
    width: 32px;
    aspect-ratio: 1;
    border-radius: 100%;
    text-align: center;
    /* visibility: hidden; */
    opacity: 0;
}

.work-programs .control:nth-child(2) {
    --animation-delay: 1.4s;
    .check {
        animation-name: toggleCheckSuggested;
    }
}
.work-programs .control.risk-low {
    --risk-color-main: var(--success-100);
    --risk-color-light: var(--success-10);
}
.work-programs .control.risk-medium {
    --risk-color-main: var(--warning-100);
    --risk-color-light: var(--warning-10);
}
.work-programs .control.risk-high {
    --risk-color-main: var(--error-100);
    --risk-color-light: var(--error-10);
}

.work-programs .control.suggested {
    .badge {
        display: block;
    }
    .check {
        background-color: var(--accent-10);
        /* visibility: visible; */
        opacity: 1;
    }
}

@keyframes clickControl {
    0%  {transform: scale(1);}
    4%  {transform: scale(.98);}
    8%  {transform: scale(1.05);}
    15% {transform: scale(1);}

    50% {transform: scale(1);}
    54% {transform: scale(.98);}
    58% {transform: scale(1.05);}
    65% {transform: scale(1);}
}

@keyframes toggleCheck {
    4%  {background-color: transparent; opacity: 0;}
    8%  {background-color: var(--accent-20); opacity: 1;}

    54% {background-color: var(--accent-20); opacity: 1;}
    58% {background-color: transparent; opacity: 0;}
}

@keyframes toggleCheckSuggested {
    4%  {background-color: var(--accent-10);}
    8%  {background-color: var(--accent-20);}

    54% {background-color: var(--accent-20);}
    58% {background-color: var(--accent-10);}
}