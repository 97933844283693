@import url('https://fonts.googleapis.com/css?family=Outfit:400,500,600,700,900');

body {
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: var(--neutral-100);
  
  --accent-10: #F9F0F1;
  --accent-20: #F0CED3;
  --accent-80: #B43A4C;
  --accent-100: #A43142;
  --accent-120: #8E2232;
  --accent-140: #7A1B29;
  
  --neutral-10: #F2F3F8;
  --neutral-20: #E8EAF2;
  --neutral-40: #CBCDDC;
  --neutral-60: #9CA0BA;
  --neutral-80: #646881;
  --neutral-100: #232639;
  --neutral-120: #050710;

  --success-10: #EAFAF3;
  --success-20: #C1F0DA;
  --success-80: #4AD389;
  --success-100: #239565;
  --success-120: #1E805C;
  --success-140: #17774F;
  
  --warning-10: #FDF7E7;
  --warning-20: #F9E7B8;
  --warning-80: #F2C247;
  --warning-100: #ECB31F;
  --warning-120: #DC9C1F;
  --warning-140: #D1881A;
  
  --error-10: #FCEDE8;
  --error-20: #F7C9BB;
  --error-80: #F28A4F;
  --error-100: #E66E2A;
  --error-120: #D0521C;
  --error-140: #C33E14;

  --shadow-color: #8900001F;
  --shadow-medium: 0 0 24px 0 var(--shadow-color);

  /** A Variabiliser */
  /* background: linear-gradient(90deg, #fff, #FFE5E5, #FFECF5, #fff); */
  overflow-x: hidden;
}

::selection {
  color: var(--accent-10);
  background-color: var(--accent-80);
}
::-moz-selection {
  color: var(--accent-10);
  background-color: var(--accent-80);
}
:focus {
  outline: 2px solid var(--accent-20);
  outline-offset: 2px;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  border-radius: 6px;
}

* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  box-sizing: border-box;
  padding: 80px 160px;
  width: 100%;
}
section.full-height {
  min-height: 100vh;
}

section .section-description h2,
section .section-description p {
  margin-bottom: 24px;
}

/** EXTRALARGE SCREEN */
@media screen and (min-width: 1600px) {
  section {
    --max-width: calc(1600px - 160px * 2);
    padding: 96px 0;
    width: 100%;
    max-width: var(--max-width);
  }
  .row {
    max-width: var(--max-width);
  }
}
/** LARGE SCREEN */
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  section {
    padding: 64px 80px;
    width: 100%;
    max-width: 1200px
  }
  .row {
    max-width: calc(1200px - 80px * 2);
  }
}
/** MEDIUM SCREEN */
@media screen and (min-width: 900px) and (max-width: 1199px) {
  section {
    padding: 64px 80px;
  }
}
/** TABLET */
@media screen and (min-width: 600px) and (max-width: 899px) {
  section {
    padding: 64px 32px;
    flex-direction: column;
  }
  .row {
    flex-direction: column;
  }
}
/** MOBILE */
@media screen and (max-width: 599px) {
  section {
    padding: 64px 24px;
    flex-direction: column;
  }
  .row {
    flex-direction: column;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.15;
  color: var(--neutral-100);
  margin: 0;
}
h1 {
  font-size: 64px;
  font-weight: 900;
}
h2 {
  font-size: 40px;
  font-weight: 900;
}
h3 {
  font-size: 24px;
  font-weight: 900;
}
h4 {
  font-size: 20px;
  font-weight: 700;
}
h5 {
  text-transform: uppercase;
  color: var(--accent-100);
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 16px;
}

p {
  margin: 0;
}

strong {
  font-weight: inherit;
}