
@keyframes accountingDataRoll {
    0.00%   {left: calc((var(--bar-width) + 4px) * -1 * 0 + 4px);}
    8.33%   {left: calc((var(--bar-width) + 4px) * -1 * 1 + 4px);}
    16.66%  {left: calc((var(--bar-width) + 4px) * -1 * 2 + 4px);}
    25%     {left: calc((var(--bar-width) + 4px) * -1 * 3 + 4px);}
    33.3%   {left: calc((var(--bar-width) + 4px) * -1 * 4 + 4px);}
    41.66%  {left: calc((var(--bar-width) + 4px) * -1 * 5 + 4px);}
    50%     {left: calc((var(--bar-width) + 4px) * -1 * 6 + 4px);}
    58.33%  {left: calc((var(--bar-width) + 4px) * -1 * 7 + 4px);}
    66.66%  {left: calc((var(--bar-width) + 4px) * -1 * 8 + 4px);}
    75%     {left: calc((var(--bar-width) + 4px) * -1 * 9 + 4px);}
    83.33%  {left: calc((var(--bar-width) + 4px) * -1 * 10 + 4px);}
    91.66%  {left: calc((var(--bar-width) + 4px) * -1 * 11 + 4px);}
    100%    {left: calc((var(--bar-width) + 4px) * -1 * 12 + 4px);}
}

.accounting-data {
    --chart-width: 160px;
    background-color: #fff;
    border-radius: 24px;
    padding: 24px;
    width: 380px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 32px;
}
.accounting-data > div {
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
}

.accounting-data .account-name {
    color: var(--neutral-100);
    font-weight: 600;
    line-height: 1.1;
}
.accounting-data .amount {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
}
.accounting-data .amount .currency {
    color: var(--accent-100);
}
.accounting-data .amount .unit {
    font-size: 20px;
    line-height: 24px;
    color: var(--neutral-60);
}

.accounting-data .right {
    flex-grow: 1;
    align-items: flex-start;
    overflow: hidden;
    max-width: var(--chart-width);
}

.accounting-data .badge {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 16px;
    background-color: var(--accent-20);
    color: var(--accent-100);
}

.accounting-data .histogram {
    position: relative;
    height: 80px;
    width: var(--chart-width);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    gap: 4px;
}
.accounting-data .histogram > div {
    position: relative;
    --bar-width: calc((var(--chart-width) / 12) - 4px);
    /* Décalage de 0.54s pour match avec les autres animations */
    animation: accountingDataRoll 12s .5s infinite cubic-bezier(0.2,1,.6,1);
    --size: 50%;
    flex-shrink: 0;
    background-color: var(--accent-100);
    opacity: var(--size);
    width: var(--bar-width);
    height: var(--size);
    border-radius: 2px;
}
.accounting-data .histogram > div.bar-0 {--size: 40%;}
.accounting-data .histogram > div.bar-1 {--size: 50%;}
.accounting-data .histogram > div.bar-2 {--size: 75%;}
.accounting-data .histogram > div.bar-3 {--size: 25%;}
.accounting-data .histogram > div.bar-4 {--size: 50%;}
.accounting-data .histogram > div.bar-5 {--size: 80%;}
.accounting-data .histogram > div.bar-6 {--size: 40%;}
.accounting-data .histogram > div.bar-7 {--size: 25%;}
.accounting-data .histogram > div.bar-8 {--size: 50%;}
.accounting-data .histogram > div.bar-9 {--size: 25%;}
.accounting-data .histogram > div.bar-10 {--size: 90%;}
.accounting-data .histogram > div.bar-11 {--size: 50%;}
.accounting-data .histogram > div.bar-12 {--size: 25%;}

.accounting-data .histogram::before {
    z-index: 2;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: linear-gradient(90deg, #ffff, #fff0)
}

.accounting-data .histogram::after {
    z-index: 2;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background: linear-gradient(-90deg, #ffff, #fff0)
}