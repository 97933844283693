.sub-section {
    width: 440px;
}

.sub-section .animation-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background-color: var(--accent-20);
    height: 240px;
    margin-bottom: 32px;
    overflow: hidden;
    position: relative;
}

.sub-section .title {
    color: var(--accent-100);
    margin-bottom: 24px;
}
.sub-section .button {
    display: none;
}

/** TABLET */
@media screen and (min-width: 600px) and (max-width: 899px) {
    .sub-section {
        width: 100%;
    }
    .sub-section .title {
        margin-bottom: 16px;
    }
    .sub-section .button {
        display: flex;
        margin-top: 24px;
    }
}

/** MOBILE */
@media screen and (max-width: 599px) {
    .sub-section {
        width: 100%;
    }
    .sub-section .title {
        margin-bottom: 16px;
    }
    .sub-section .button {
        display: flex;
        margin-top: 24px;
    }
}