.hero-section.hero-section {
    text-align: center;
}
.hero-section.hero-section.parallax {
    perspective: 100vw;
    -webkit-animation: none;
    animation: slowParallax linear both;
    -moz-animation: none;
    animation-timeline: scroll();
    animation-range-end: 200vw;
}
/* ? trick pour forcer ces attributs à être définis seulement APRES l'attribut "animation" */
.hero-section.parallax {
    animation-timeline: scroll();
    animation-range-end: 200vw;
}

@keyframes slowParallax {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(640px);
    }
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 120vh;
}

.hero-section h1 {
    margin-bottom: 24px;
}
.hero-section p {
    font-size: 20px;
    margin-bottom: 32px;
    max-width: 900px;
}

.browser {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 0 24px 0 #8900001F;
    margin-top: 32px;
    width: 100%;
}

.browser .browser-header {
    display: flex;
    padding: 4px 16px;
    background-color: #F8F7FF;
}
.browser .browser-header .browser-header-left {
    display: flex;
    align-items: center;
    gap: 5px;
    
}
.browser .browser-header .browser-header-left,
.browser .browser-header .browser-header-right {
    flex: 1 0;
}
.browser .browser-header .browser-header-center {
    width: 40%;
}

.browser .browser-header .browser-header-button {
    display: inline-flex;
    width: 10px;
    aspect-ratio: 1;
    border-radius: 100%;
}
.browser .browser-header .browser-header-button.close {
    background-color: var(--error-80);
}
.browser .browser-header .browser-header-button.expand {
    background-color: var(--success-80);
}
.browser .browser-header .browser-header-button.reduce {
    background-color: var(--warning-80);
}

.browser .search-bar {
    color: var(--neutral-80);
    padding: 8px;
    border: 1px solid var(--neutral-40);
    font-size: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #fff;
}

.browser.browser {
    animation: tiltBrowser linear;
    animation-timeline: scroll();
    animation-range-start: 0px;
    animation-range-end: 500px;
    transform-origin: center center;
}
.browser {
    animation-timeline: scroll();
    animation-range-start: 0px;
    animation-range-end: 500px;
}

@keyframes tiltBrowser {
    from {
        transform: rotateX(25deg);
    }
    to {
        transform: rotateX(0);
    }
}

/** MOBILE */
@media screen and (max-width: 599px) {
    .hero-section {
        animation: none !important;
        text-align: left !important;
    }
    .browser {
        animation: none !important;
        .search-bar {
            font-size: 12px;
            line-height: 12px;
        }
    }
    h1 {
        font-size: 48px;
    }
}