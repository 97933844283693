.working-papers {
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;
    width: 380px;
}
.working-papers table {
    background-color: var(--neutral-60);
    border-spacing: 1px;
    font-size: 16px;
}

.working-papers table th, 
.working-papers table td {
    padding: 8px 12px;
    background-color: var(--neutral-10);
    &.account {
        text-align: left;
    }
    &.debit, &.credit {
        text-align: right;
    }
}
.working-papers table td {
    background-color: #fff;
}