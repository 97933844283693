select {
    height: 24px;
    padding: 2px 4px;
    line-height: 20px;
    border-radius: 6px;
    border-color: var(--neutral-60);
    transition: all .15s;
}
select:hover {
    border-color: var(--neutral-100);
}