.synthesis {
    display: flex;
    align-items: center;
    gap: 24px;
}

.synthesis .file-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: #fff;
}

.synthesis .item-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border-radius: 24px;
    background-color: #fff;
}
.synthesis .item-list li {
    --direction: 1;
    --bounce-curve: cubic-bezier(.1,.5,.5,1);
    --animation-delay: .5s;
    display: flex;
    width: 64px;
    aspect-ratio: 1;
    position: relative;
    animation: rollSynthesisItems 6s var(--animation-delay) var(--bounce-curve) infinite;
}
.synthesis .item-list:nth-of-type(2) li {
    --direction: -1;
    /* animation-direction: reverse; */
}

@keyframes rollSynthesisItems {
    0.00%   {transform: translateY(calc(72px * var(--direction) * 0));}
    16.66%  {transform: translateY(calc(72px * var(--direction) * 1));}
    33.3%   {transform: translateY(calc(72px * var(--direction) * 2));}
    50%     {transform: translateY(calc(72px * var(--direction) * 3));}
    66.66%  {transform: translateY(calc(72px * var(--direction) * 4));}
    83.33%  {transform: translateY(calc(72px * var(--direction) * 5));}
    100%    {transform: translateY(calc(72px * var(--direction) * 6));}
}