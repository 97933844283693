.header-wrapper {
    position: sticky;
    top:0;
    width: 100%;
    display: flex;
    /* padding: 16px; */
    z-index: 10;
}

header {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 0 0 0 rgba(52, 0, 16, 0.10);
    transition: all .25s;
}
.header-wrapper.sticky header {
    box-shadow: 0 4px 24px 8px rgba(52, 0, 16, 0.10);
    background-color: #fff;
}

header img {
    display: block;
}

header nav {
    display: flex;
    gap: 8px;
}

header nav .nav-button {
    color: var(--neutral-100);
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 16px;
    transition: all .2s;
    border-radius: 6px;
}
header nav .nav-button:hover {
    color: var(--accent-100);
    background-color: #fff;
}
.header-wrapper.sticky header nav .nav-button:hover {
    background-color: var(--accent-10);
}

header .header-left,
header .header-right {
    flex: 1 0;
}
header .header-right {
    justify-content: flex-end;
}

aside {
    --transition-speed: .25s;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 11;
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    /* height: 100vh; */
    transition: right ease-in var(--transition-speed);
    padding: 8px;
    padding-top: 80px;
    padding-right: 0;
}
aside.show {
    right: 0;
}
aside .overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 100vw;
    opacity: 0;
    background-color: rgba(12, 8, 34, 0.25);
    transition:
        opacity ease-in-out var(--transition-speed),
        left ease-in-out var(--transition-speed) var(--transition-speed);
    backdrop-filter: blur(1px);
}
aside.show .overlay {
    left: 0;
    opacity: 1;
    transition:
        opacity ease-in-out var(--transition-speed);
}
aside .mobile-menu-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px 32px;
    background-color: #fff;
    border-radius: 24px 0 0 24px;
    box-shadow: 0 0 32px 0 rgb(0 0 0 /15%);
}
aside .mobile-menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 48px;
    aspect-ratio: 1;
    border-radius: 8px;
    border: 1px solid var(--neutral-100);
    background-color: transparent;
    cursor: pointer;
    &:hover {
        background-color: var(--neutral-20);
    }
    &:active {
        background-color: var(--neutral-60);
    }
}

aside nav {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
/* aside nav .nav-button,
aside nav .button {
    display: flex;
    justify-content: center;
    padding: 24px 32px;
    width: 100%;
    text-align: center;
    border: 1px solid var(--neutral-100);
    border-radius: 8px;
    font-size: 16px;
} */
aside nav .nav-button:active {
    background-color: var(--accent-20);
    border-color: var(--accent-20);
    color: var(--accent-100);
}

.sticky-cta {
    display: none;
    position: fixed;
    z-index: 5;
    bottom: 0;
    width: 100%;
    padding: 16px;
}

/** LARGE SCREEN + */
@media screen and (min-width:1200px) {
    .header-wrapper .toggle-button {
        display: none;
    }
}
/** MEDIUM SCREEN */
@media screen and (min-width:900px) and (max-width:1199px) {
    header .nav-button {
        display: none;
    }
    aside .mobile-menu-content {
        width: 600px;
    }
}
/** TABLET */
@media screen and (min-width:600px) and (max-width:899px) {
    header .nav-button {
        display: none;
    }
    aside .mobile-menu-content {
        width: 400px;
    }
}
/** MOBILE */
@media screen and (max-width:599px) {
    header .logo img {
        height: 48px;
    }
    header .nav-button,
    header .button {
        display: none;
    }
    aside .mobile-menu-content {
        width: 100%;
        & .nav-buttons {
            flex-grow: 1;
            justify-content: center;
            & .nab-button {
                font-size: 20px;
            }
        }
    }
    .sticky-cta.show {
        display: block;
    }
}