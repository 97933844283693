.all-in-one {
    display: flex;
    gap: 80px;
    /* padding-top: 128px;
    padding-bottom: 128px; */
}

.all-in-one .sticky-heading {
    flex-grow: 1;
    position: relative;
}
.all-in-one .sticky-heading-content {
    top: 160px;
    position: sticky;
    padding-bottom: 96px;
}

.all-in-one .sub-sections {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

@media screen and (max-width: 899px) {
    .all-in-one .sticky-heading-content {
        padding-bottom: 0;
    }
    .all-in-one .sub-sections {
        gap: 64px;
    }
}