.excel {
    display: flex;
    gap: 64px;
}

.excel .section-description {
    padding-top: 32Px;
    text-align: right;
    width: 32%;
}
.excel .section-description h2 strong {
    color: var(--accent-100);
}

.excel .excel-windows {
    --x: 0;
    --y: 0;
    --base-tilt: 15;
    width: 640px;
    aspect-ratio: 1.42;
    position: relative;
    flex-grow: 1;
    perspective: calc(100vw*1);
}
.excel .excel-windows .svg-frame {
    z-index: calc(2 + var(--i));
    border-radius: 1vw;
    --shadow-color: #000;
    --tilt-x: calc(var(--base-tilt) + var(--y) * -1 / 40);
    --tilt-y: calc(var(--base-tilt) + var(--x) / 40);
    --shift-x: calc(var(--x) * var(--i) * 1px / 20);
    --shift-y: calc(var(--y) * var(--i) * 1px / 20);
    --translate-x: calc(24px * var(--i) + var(--shift-x));
    --translate-y: calc(-24px * var(--i) + var(--shift-y));
    --translate-z: calc(16px * (var(--i)));
    position: absolute;
    bottom:0;
    right: 0;
    transform:
        translateX(var(--translate-x))
        translateY(var(--translate-y))
        translateZ(var(--translate-z))
        rotateX(calc(1deg * var(--tilt-x)))
        rotateY(calc(1deg * var(--tilt-y)));
        /* rotateX(15deg) */
        /* rotateY(35deg); */
    transition: all .5s ease-out;
    transform-style: preserve-3d;
    transform-origin: center center;
    border: 1px solid var(--shadow-color);
    box-shadow:
        var(--shadow-color) calc(1px * var(--tilt-y) / -4) calc(1px * var(--tilt-x) / 4) 0 0,
        rgba(11, 0, 42, 0.15) -24px 24px 32px 8px;
}

.excel .excel-windows .svg-frame.excel-window {
    --i: -1;
    --shadow-color: var(--success-120);
    width: 100%;
}
.excel .excel-windows .svg-frame.excel-window.bis {
    --i: 0;
}
.excel .excel-windows .svg-frame.excel-table {
    --i: 1;
    --shadow-color: var(--neutral-100);
    width: 80%;
}
.excel .excel-windows .svg-frame.dreamoffice {
    --i: 2;
    --shadow-color: var(--accent-100);
    width: 35%;
    right: calc(50% - 128px);
}

/* Animation on scroll */
/* @supports (animation-timeline: scroll()) {
    .excel .excel-windows .svg-frame {
        animation: rotateWindows linear both;
        animation-timeline: view(500px);
        animation-range: contain;
    }
} */
/* .excel .excel-windows .svg-frame {
    animation: rotateWindows linear both;
    animation-timeline: scroll();
    animation-range-start: 400px;
} */
/* @keyframes rotateWindows {
    from {
        transform:
            translateX(calc(24px * var(--i)))
            translateY(calc(-24px * var(--i)))
            translateZ(calc(0px * var(--i)))
            rotateX(15deg)
            rotateY(35deg);
    }
    to {
        transform:
            translateX(calc(24px * var(--i)))
            translateY(calc(-24px * var(--i)))
            translateZ(calc(0px * var(--i)))
            rotateX(15deg)
            rotateY(15deg);
    }
} */

@media screen and (max-width: 1199px) {
    .excel {
        flex-direction: column-reverse;
        align-items: center;
    }
    .excel .section-description {
        width: 100%;
        text-align: left;
    }
    .excel .excel-windows {
        --base-tilt: 0;
        width: 92%;
    }
}
/* @media screen and (max-width: 599px) {
    .excel .section-description .button {
        display: none;
    }
} */