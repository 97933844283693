.footer {
    position: relative;
    z-index: 6;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: inherit;
}

.footer .section-description {
    max-width: 400px;
    text-align: center;
    padding-top: 32px;
}
.footer .section-description h2,
.footer .section-description p {
    margin-bottom: 32px;
}
.footer .section-description p {
    color: var(--neutral-80);
}
.footer .section-description strong {
    font-weight: 500;
    color: var(--neutral-100);
}

.footer .cloud {
    --small-parts-distance: 640px;
    --small-parts-size: 50vw;
    position: absolute;
    z-index: -1;
    inset: 0;
}
.footer .cloud .cloud-part {
    position: absolute;
    z-index: -10;
    border-radius: 100%;
    background-color: #fff;
    box-shadow:
        rgba(115, 97, 148, 0.15) 0 0 32px 0,
        rgba(255, 157, 175, 0.15) 0 24px 24px 0 inset;
}
.footer .cloud .cloud-part.main {
    z-index: -9;
    height: 120%;
    width: 1200px;
    top: 132px;
    left: calc(100vw/2 - 600px);
}
.footer .cloud .cloud-part.left,
.footer .cloud .cloud-part.right {
    top: 40vh;
    width: var(--small-parts-size);
    aspect-ratio: 1;
}
.footer .cloud .cloud-part.left {
    z-index: -8;
    left: calc(100vw / 2 - var(--small-parts-distance) - var(--small-parts-size) / 2);
}
.footer .cloud .cloud-part.right {
    z-index: -10;
    left: calc(100vw / 2 + var(--small-parts-distance) - var(--small-parts-size) / 2);
}
.footer .cloud .mask {
    position: absolute;
    z-index: -7;
    background-color: #fff;
    border-radius: 100%;
    bottom: -25vh;
    height: 75vh;
    width: 100%;
    box-shadow: #fff 0 0 40px 0;
}

.footer footer {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    padding: 24px 80px 56px;
    border-top: 1px solid var(--neutral-40);
}

.footer footer .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer footer ul {
    display: flex;
    gap: 24px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer footer .logo {
    display: flex;
    & img {
        height: 24px;
    }
}

.footer footer .legal {
    color: var(--neutral-80);
}
.footer footer .legal a:hover {
    color: var(--accent-100);
}
.footer footer .nav-links a {
    font-weight: bold;
    color: var(--neutral-100);
    transition: color .2s;
}
.footer footer .nav-links a:hover {
    color: var(--accent-100);
}

.footer footer .social-links {
    gap: 8px;
}
.footer footer a.social-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    aspect-ratio: 1;
    border-radius: 4px;
    background-color: var(--accent-20);
    transition: all .15s;
    outline-offset: 2px;
    &:hover {
        outline: 2px solid var(--accent-20);
    }
}
.footer footer a.social-link img {
    fill: #fff;
    height: 16px;
    width: auto;
}

/** MOBILE TABLET */
@media screen and (max-width: 899px) {
    .footer {
        footer {
            padding: 32px;
        }
        .row {
            flex-direction: row;
        }
    }
}

/** MOBILE TABLET */
@media screen and (max-width: 599px) {

    .footer {
        padding-bottom: 32px;
        footer {
            & ul {
                justify-content: center;
            }
            & .nav-links a {
                font-size: 20px;
                line-height: 20px;
            }
            & a.social-link {
                width: 40px;
                & img {
                    height: 24px;
                }
            }
            & .legal {
                font-size: 12px;
                line-height: 16px;
                gap: 16px;
            }
            & .row:first-child {
                width: auto;
                flex-direction: row;
                gap: 24px;
                margin-bottom: 24px;
            }
            & .row:last-child {
                flex-direction: column-reverse;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
            }
        }
    }
    .footer {
        justify-content: flex-end;
        .section-description {
            width: 100%;
            max-width: inherit;
        }
        footer {
            align-items: center;
            padding: 0;
            padding-top: 40px;
            margin-top: 40px;
            width: 100%;
            position: inherit;
            
            & .logo img {
                height: 40px;
                width: auto;
            }
        }
    }
}