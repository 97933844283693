.collaboration {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.collaboration .section-description {
    text-align: center;
}

.collaboration .text-section {
    display: flex;
    align-items: flex-start;
    gap: 48px;
    padding: 16px 64px;
}
.collaboration .text-section > .title {
    flex-grow: 1;
    flex-basis: 0;
    text-align: right;
}
.collaboration .text-section p,
.collaboration .text-section ol {
    margin: 0;
    flex-basis: 0;
    flex-grow: 2;
}
.collaboration .text-section ol li {
    margin-bottom: 16px;
}

.collaboration .row {
    display: flex;
    align-items: stretch;
    gap: 32px;
}


.collaboration .image-desc {
    flex: 1 0;
    min-width: 0;
    max-height: 640px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.collaboration .image-desc img {
    object-fit: cover;
    object-position: center center;
    flex-grow: 1;
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 24px;
}
.collaboration .image-desc.img-left img {
    object-position: left center;
}
.collaboration .image-desc.img-right img {
    object-position: right center;
}
.collaboration .image-desc.mobile {
    flex-grow: .5;
    width: 320px;
    img {
        aspect-ratio: .5;
        object-position: center top;
    }
}
.collaboration .image-desc small {
    text-align: center;
    color: var(--neutral-80);
}

/** LARGE DESKTOP */
@media screen and (min-width: 900px) and (max-width: 1199px) {
    .collaboration .text-section {
        padding: 0 0;
        /* gap: 16px; */
    }
    .collaboration .text-section > .title {
        text-align: right;
    }
    .collaboration .image-desc {
        width: 100%;
    }
    .collaboration .row {
        flex-direction: column;
        align-items: center;
    }
}

/** MOBILE TABLET */
@media screen and (max-width: 899px) {
    .collaboration .text-section {
        flex-direction: column;
        padding: 24px 0;
        gap: 16px;
    }
    .collaboration .text-section > .title {
        text-align: left;
    }
    .collaboration .image-desc {
        width: 100%;
    }
    .collaboration .row {
        flex-direction: column;
        align-items: center;
    }
}