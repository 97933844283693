.argument-frame {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-color: #fff;
    border-radius: 40px;
    padding: 48px 32px;
    border: 2px solid var(--neutral-40);
    transition: all .2s ease-out;
}
.argument-frame:hover {
    transform: scale(1.05);
}

.argument-frame .argument-frame-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 24px;
}

.argument-frame .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    min-width: 128px;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: var(--neutral-10);
}

.argument-frame .desc {
    text-align: center;
    flex-grow: 1;
}
.argument-frame .desc h3 {
    margin-bottom: 8px;
}
.argument-frame .desc p {
    font-size: 20px;
}

.argument-frame .button {
    width: 100%;
}

/** MOBILE */
@media screen and (max-width: 599px) {
    .argument-frame {
        border: 0;
        padding: 0;
        &:hover {
            transform: none
        }
    }
    .argument-frame .argument-frame-header {
        flex-direction: row;
        align-items: center;
    }
    .argument-frame .desc {
        align-items: flex-start;
        text-align: left;
    }
}

/** TABLET */
@media screen and (min-width: 600px) and (max-width: 899px) {
    .argument-frame {
        border: 0;
        padding: 0;
    }
    /* .argument-frame .argument-frame-header {
        flex-direction: row;
        align-items: flex-start;
    }
    .argument-frame .desc {
        align-items: flex-start;
        text-align: left;
    } */
}