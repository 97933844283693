.gradient-background {
    position: fixed;
    inset: 0;
    z-index: -100;
    background: #fff;
}

/** Backgrounds */
.gradient-background {
    background-color: #FFE5E5;
    transition: background-color 1s ease-out;
}
.gradient-background.current-collaborative {
    background-color: #D3E7F5;
}
.gradient-background.current-innovative,
.gradient-background.current-footer {
    background-color: #C6CCFF;
}

.App {
    background: linear-gradient(92deg, #fffC, #fff0, #fff2, #fff7);
}