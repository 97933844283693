.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 16px 20px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    transition: all .15s;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    white-space: nowrap;
}

.button.button-primary {
    background-color: var(--accent-100);
    color: #fff;
}
.button.button-primary:hover,
.button.button-primary:focus {
    background-color: var(--accent-120);
}
.button.button-primary:active {
    background-color: var(--accent-140);
}

.button.button-secondary {
    border: 1px solid var(--accent-100);
    color: var(--accent-100);
}
.button.button-secondary:hover,
.button.button-secondary:focus {
    background-color: var(--accent-100);
    color: #fff;
}
.button.button-secondary:active {
    background-color: var(--accent-120);
}

.button.button-tertiary {
    font-weight: 700;
    background-color: var(--neutral-10);
    color: var(--neutral-100);
}
.button.button-tertiary:hover,
.button.button-tertiary:focus {
    background-color: var(--accent-100) !important;
    color: #fff;
}

.button.size-large {
    font-size: 20px;
    height: 64px;
    padding: 24px;
}

@media screen and (max-width:599px) {
    .button {
        width: 100%;
        text-align: center;
        padding: 32px 32px;
        font-size: 20px;
        line-height: 24px;
    }
}