@keyframes riskRollText {
    0%   {top: 0;}
    18%  {top: 0;}
    33%  {top: calc(var(--roll-height) * -1);}
    51%  {top: calc(var(--roll-height) * -1);}
    66%  {top: calc(var(--roll-height) * -2);}
    85%  {top: calc(var(--roll-height) * -2);}
    100% {top: calc(var(--roll-height) * -3);}
}

.risk-assessment {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 380px;
    padding: 24px;
    border-radius: 24px;
}

.risk-assessment ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.risk-assessment .left {
    flex-grow: 1;
}
.risk-assessment .question {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 16px;
    display: block;
}

.risk-assessment .isa {
    display: flex;
    gap: 8px;
}
.risk-assessment .isa .badge {
    background-color: var(--neutral-10);
    color: var(--neutral-60);
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    border-radius: 4px;
    padding: 4px 8px;
}

.risk-assessment .risk-level {
    --roll-height: 32px;
    text-align: right;
}
.risk-assessment .risk-level ul {
    height: var(--roll-height);
    overflow: hidden;
}
.risk-assessment .risk-level ul li {
    animation: riskRollText 3s ease-out infinite;
    position: relative;
    font-size: 20px;
    line-height: var(--roll-height);
    font-weight: bold;
}

.risk-assessment .risk-level ul li.low {color: var(--success-100);}
.risk-assessment .risk-level ul li.medium {color: var(--warning-100);}
.risk-assessment .risk-level ul li.high {color: var(--error-100);}
.risk-assessment .risk-level .risk-label {
    display: block;
    font-size: 16px;
    color: var(--neutral-80);
}

.risk-assessment .indicator {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-20);
}
.risk-assessment .indicator li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    aspect-ratio: 1;
}
.risk-assessment .indicator li.low {
    --color: var(--success-100);
}
.risk-assessment .indicator li.low .circle {
    animation-name: riskRollIndicatorLow;
}
.risk-assessment .indicator li.medium {
    --color: var(--warning-100);
}
.risk-assessment .indicator li.medium .circle {
    animation-name: riskRollIndicatorMedium;
}
.risk-assessment .indicator li.high {
    --color: var(--error-100);
}
.risk-assessment .indicator li.high .circle {
    animation-name: riskRollIndicatorHigh;
}
.risk-assessment .indicator li .circle {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--color);
    width: 8px;
    border-radius: 100%;
}
.risk-assessment .indicator li .circle img {
    width: 100%;
    aspect-ratio: 1;
}

@keyframes riskRollIndicatorMedium {
    18%  {background-color: var(--color); width: 8px;}
    33%  {background-color: transparent;  width: 16px;}
    51%  {background-color: transparent;  width: 16px;}
    66%  {background-color: var(--color); width: 8px;}
}
@keyframes riskRollIndicatorHigh {
    51%  {background-color: var(--color); width: 8px;}
    66%  {background-color: transparent;  width: 16px;}
    85%  {background-color: transparent;  width: 16px;}
    100%  {background-color: var(--color); width: 8px;}
}
@keyframes riskRollIndicatorLow {
    85%  {background-color: var(--color); width: 8px;}
    100% {background-color: transparent;  width: 16px;}
    0% {background-color: transparent;  width: 16px;}
    18%  {background-color: transparent;  width: 16px;}
    33%  {background-color: var(--color); width: 8px;}
}